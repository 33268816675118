<template>
  <mdb-container>
    <mdb-col>
      <div class="mt-5 sub_content">
        <h3>아이디어 접수</h3>
      </div>
      <div class="my-3 card">
        <mdb-tbl reponsive>
          <mdb-tbl-head style="background-color: #ececec;">
            <tr>
              <th colspan="4" style="font-size: 20px;">{{ title }}</th>
            </tr>
          </mdb-tbl-head>
          <mdb-tbl-body>
            <tr>
              <th>등록일</th>
              <td>{{ reg_date }}</td>
              <th>평점</th>
              <td>{{ rating }}</td>
            </tr>
            <tr>
              <th>작성자</th>
              <td>{{ writer }}</td>
              <th>조회수</th>
              <td>{{ views }}</td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>{{ email }}</td>
              <th>전화번호</th>
              <td>{{ mobile }}</td>
            </tr>
            <tr>
              <th style="vertical-align: middle">파일</th>
              <td colspan="3">
                <ul>
                  <li v-if="file_name !== ''">
                    <a :href="file_name"> {{ file_url }}</a>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>신분</th>
              <td colspan="3">{{ identity }}</td>
            </tr>
            <tr>
              <td colspan="4" style="text-align: center">
                <div v-if="image_url !== ''">
                  <img :src="image_url" alt="img" class="image_center">
                </div>
                <div class="my-3" v-if="contents !== ''">
                  {{ contents }}
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div>
                  <h6>제안배경</h6>
                  <p>{{ reason }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div>
                  <h6>필요성</h6>
                  <p>{{ necessity }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div>
                  <h6>용도</h6>
                  <p>{{ purpose }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <div>
                  <h6>기대효과</h6>
                  <p>{{ effect }}</p>
                </div>
              </td>
            </tr>
          </mdb-tbl-body>
        </mdb-tbl>
      </div>
    </mdb-col>
    <mdb-col class="text-center my-3">
      <div>
        <a class="btn-floating btn-lg btn-indigo" v-if="!on_like_btn" @click="onClickLike(true)">
          <mdb-icon far icon="thumbs-up"/>
        </a>
        <a class="btn-floating btn-lg btn-indigo" v-if="on_like_btn" @click="onClickLike(false)">
          <mdb-icon icon="thumbs-up"/>
        </a>
        <a class="btn-floating btn-lg btn-amber" @click="clip">
          <mdb-icon icon="link"/>
        </a>
      </div>
      <div>
        <p class="likeCnt">{{ likeCnt }}</p>
        <p class="linkCnt"></p>
      </div>
    </mdb-col>
    <mdb-container class="mt-5">
      <mdb-card style=" text-align: center; margin: 0 auto; padding: 18px; ">
        <mdb-card-body>
          <h3 v-if="!on_rating_btn">아이디어가 어떤가요?</h3>
          <h3 v-else>내가 준 점수는</h3>
          <star-rating
              style="display: inline-block"
              text-class="custom-text"
              v-model="my_rating"
              :show-rating="false"
              :star-size="30"/>
          <p class="mt-3" style="font-size: 16px; color: #212529"></p>
          <a class="btn-floating btn-lg btn-green" v-if="!on_rating_btn" @click="onClickRating">
            <mdb-icon icon="check"/>
          </a>
          <p v-else>{{ my_rating }} 점 입니다.</p>
        </mdb-card-body>
      </mdb-card>
    </mdb-container>
    <mdb-container class="mt-5">
      <mdb-row>
        <mdb-col>
          <mdb-input placeholder="작성자명을 입력하세요. (미입력시 익명)" basic class="mb-3" v-model="reply_writer">
            <span class="input-group-text" slot="prepend"><i class="fas fa-user prefix"></i></span>
          </mdb-input>
          <mdb-input type="textarea" basic v-model="reply">
            <span class="input-group-text" slot="prepend"> <i class="fas fa-pencil-alt prefix"></i></span>
          </mdb-input>
        </mdb-col>
        <mdb-btn style="background-color: #4b4242 !important;" @click="setWriteReply">댓글달기</mdb-btn>
      </mdb-row>
    </mdb-container>
    <mdb-col class="reply-form my-5 card">
      <div>
        <ul>
          <Reply :replies="replies[i]" v-for="(a,i) in replies" :key="i" @update="getRepliesData"/>
        </ul>
      </div>
    </mdb-col>
    <input type="text" id="urlInput" class="url-input"/>
    <div class="mb-5 text-right" style="text-align: center">
      <mdb-btn outline="danger" small icon="trash" @click="deletePost">삭제</mdb-btn>
    </div>
  </mdb-container>
</template>

<script>
import {
  mdbIcon,
  mdbCol,
  mdbContainer,
  mdbTbl,
  mdbTblBody,
  mdbTblHead,
  mdbBtn,
  mdbRow,
  mdbInput,
  mdbCard,
  mdbCardBody
} from 'mdbvue';
import firebase from "firebase/app";
import Reply from "./Reply";
import StarRating from 'vue-star-rating'

export default {
  name: "IdeaView",
  data() {
    return {
      id: this.$route.query.id,
      contents: '',
      files: [],
      image_url: '',
      reg_date: '',
      title: '',
      views: 0,
      writer: '',
      email: '',
      reason: '',
      necessity: '',
      purpose: '',
      effect: '',
      identity: '',
      reply_contents: '',
      reply_reg_date: '',
      reply_writer: '',
      replies: [],
      reply: '',
      on_like_btn: false,
      likeCnt: 0,
      rating: 0,
      on_rating_btn: false,
      my_rating: 0,
      file_name: '',
      file_url: '',
      mobile: '',
      uid: '',
    }
  },
  components: {
    mdbCardBody,
    mdbCard,
    mdbRow,
    mdbInput,
    Reply,
    mdbBtn,
    mdbContainer,
    mdbTblHead,
    mdbCol,
    mdbTbl,
    mdbTblBody,
    mdbIcon,
    StarRating
  },
  mounted() {
    this.onFirebaseLoadData();
    this.setViewCountAdd();
    this.getRepliesData();
    this.onCheckUsers();

  },
  methods: {
    setViewCountAdd() {
      const self = this;
      const db = firebase.firestore();

      const docRef = db.collection('ideaBoard').doc(self.id);

      db.runTransaction((transaction) => {
        return transaction.get(docRef).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }
          const newViews = sfDoc.data().views + 1;
          if (newViews <= 1000000) {
            transaction.update(docRef, {views: newViews});
            return newViews;
          } else {
            return Promise.reject("Sorry! Population is too big.");
          }
        });
      }).then(() => {
        const uid = self.$store.state.firebase_uid;
        const childRef = docRef.collection('checkUsers').doc(uid);
        db.runTransaction((transaction) => {
          return transaction.get(childRef).then((ssfDoc) => {

            if (!ssfDoc.exists) {
              self.onNewUserEntrance();
              // console.log(uid + "등록해야지")
              return false;
            }
            transaction.update(childRef, {views: true});
          })
        })
      }).catch((err) => {
        // This will be an "population is too big" error.
        console.error(err);
      });
    },

    /**
     * 신규 유저가 등장
     */
    onNewUserEntrance() {
      const self = this;
      const db = firebase.firestore();
      const uid = self.$store.state.firebase_uid;
      const docRef = db.collection('ideaBoard').doc(self.id);
      const childRef = docRef.collection('checkUsers').doc(uid);
      childRef.set({views: true, likes: false, rating: 0})
          .then(() => {
            // console.log(uid + "가 등록됨")
          })
          .catch((error) => {
            console.log(error);
          })
    },

    async onFirebaseLoadData() {
      const self = this;
      const db = firebase.firestore();
      const docRef = db.collection("ideaBoard");

      // 본문을 가져와
      await docRef.doc(self.id).get()
          .then((querySnapshot) => {
            const _data = querySnapshot.data();
            // console.log(_data)
            self.contents = _data.contents;
            if (!self.isEmpty(_data.files)) {
              self.files = Object.entries(_data.files);
              self.file_url = Object.entries(_data.files)[0][0];
              self.file_name = Object.entries(_data.files)[0][1];
            }
            self.image_url = _data.image_url;
            const date = new Date(_data.reg_date.seconds * 1000);
            self.reg_date = self.getParseDate(date);
            self.title = _data.title;
            self.rating = _data.rating;
            self.views = _data.views;
            self.email = _data.email;
            self.writer = _data.writer;
            self.reason = _data.reason;
            self.necessity = _data.necessity;
            self.purpose = _data.purpose;
            self.effect = _data.effect;
            self.identity = _data.identity;
            self.likeCnt = _data.likes;
            self.uid = _data.uid;
            if (_data.identity === "citizen") {
              self.identity = "국민"
            } else if (_data.identity === "police") {
              self.identity = "경찰관"
            }
          });

      this.getMobile();

    },
    onCheckUsers() {
      const self = this;
      if (!self.$store.state.login) {
        return;
      }
      const uid = self.$store.state.firebase_uid;
      const db = firebase.firestore();
      const docRef = db.collection("ideaBoard").doc(self.id);
      docRef.collection('checkUsers').doc(uid)
          .get()
          .then((querySnapshot) => {
            const _data = querySnapshot.data();
            // console.log(_data)
            if (_data.views) {
              // console.log('본적있음')
            }
            if (_data.likes) {
              // console.log('좋아한적있음')
              self.on_like_btn = true
            } else {
              // console.log('좋아한적없음')
              self.on_like_btn = false
            }
            if (_data.rating) {
              // console.log('점수준적있음')
              self.my_rating = _data.rating;
              self.on_rating_btn = true
            } else {
              // console.log('점수준적없음')
              self.my_rating = 0;
              self.on_rating_btn = false
            }
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });

    },
    /**
     * 좋아요 버튼을 누름
     */
    onClickLike(b) {
      const self = this;
      if (!self.$store.state.login) {
        alert('로그인이 필요합니다.')
        return;
      }


      if (b) {
        self.setLikeCount(1, b)
      } else {
        const confirmDialog = confirm('좋아요를 취소할까요?');
        if (confirmDialog) {
          self.setLikeCount(-1, b)
        }

      }
    },

    /**
     * 좋아요 카운트 증가
     * @param value
     * @param isLikes
     */
    setLikeCount(value, isLikes) {
      const self = this;
      const db = firebase.firestore();
      const docRef = db.collection('ideaBoard').doc(self.id);
      db.runTransaction((transaction) => {
        return transaction.get(docRef).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }
          const newLikes = sfDoc.data().likes + value;
          if (newLikes <= 1000000) {
            transaction.update(docRef, {likes: newLikes});
            return newLikes;
          } else {
            return Promise.reject("Sorry! Population is too big.");
          }
        });
      }).then(() => {

        const uid = self.$store.state.firebase_uid;
        const childRef = docRef.collection('checkUsers').doc(uid);
        db.runTransaction((transaction) => {
          return transaction.get(childRef).then((sfDoc) => {
            if (!sfDoc.exists) {
              return false;
            }
            transaction.update(childRef, {likes: isLikes});
          });
        }).then(() => {
          if (isLikes) {
            self.likeCnt++;
            self.on_like_btn = true;
          } else {
            self.likeCnt--;
            self.on_like_btn = false;

          }
        })
      }).catch((err) => {
        // This will be an "population is too big" error.
        console.error(err);
      });
    },

    /**
     * 별점 버튼을 누름
     */
    onClickRating() {
      const self = this;
      if (!self.$store.state.login) {
        alert('로그인이 필요합니다.')
        return;
      }
      const confirmDialog = confirm('평가는 한번만 가능합니다.\n' + self.my_rating + '점으로 등록합니다.');
      if (confirmDialog) {
        self.setRatingCount()
      }
    },

    /**
     * 별점 카운트 증가
     */
    setRatingCount() {
      const self = this;
      const db = firebase.firestore();
      const docRef = db.collection('ideaBoard').doc(self.id);
      db.runTransaction((transaction) => {
        return transaction.get(docRef).then((sfDoc) => {
          if (!sfDoc.exists) {
            throw "Document does not exist!";
          }
          const nowRating = sfDoc.data().likes;
          let newRating;
          if (nowRating === 0) {
            newRating = self.my_rating;
          } else {
            newRating = (nowRating + self.my_rating) / 2;
          }
          if (newRating <= 1000000) {
            transaction.update(docRef, {rating: newRating});
            self.rating = newRating;
            return newRating;
          } else {
            return Promise.reject("Sorry! Population is too big.");
          }
        });
      }).then(() => {
        const uid = self.$store.state.firebase_uid;
        const childRef = docRef.collection('checkUsers').doc(uid);
        db.runTransaction((transaction) => {
          return transaction.get(childRef).then((sfDoc) => {
            if (!sfDoc.exists) {
              return false;
            }
            transaction.update(childRef, {rating: self.my_rating});
          });
        }).then(() => {
          self.on_rating_btn = true;
        })
      }).catch((err) => {
        // This will be an "population is too big" error.
        console.error(err);
      });


    },

    isEmpty(param) {
      return Object.keys(param).length === 0;
    },
    clip() {
      const urlInput = document.getElementById("urlInput");
      urlInput.value = window.document.location.href; //clipboard 복사
      urlInput.select();
      document.execCommand("copy");
      urlInput.blur();

      alert("URL이 복사되었습니다.")
    },
    /**
     * 댓글 불러오기
     */
    getRepliesData() {
      const self = this;
      const db = firebase.firestore();
      const docRef = db.collection("ideaBoard");
      self.replies = [];

      // 댓글을 가져와
      docRef.doc(self.id).collection('replies').orderBy("reply_reg_date").get()
          .then((querySnapshot) => {
            self.totCount = querySnapshot.size
            querySnapshot.forEach((doc) => {
              const _data = doc.data();
              // console.log(_data);
              _data["post_id"] = self.id;
              _data["reply_id"] = doc.id;
              const date = new Date(_data.reply_reg_date.seconds * 1000);
              _data.reply_reg_date = self.getParseDate(date);
              self.replies.unshift(_data);

              // console.log(self.replies);
            });
          })
    },

    setWriteReply() {
      const self = this;
      const db = firebase.firestore();

      const data = {
        reply_contents: self.reply,
        reply_writer: self.reply_writer,
        uid: self.$store.state.firebase_uid,
        reply_reg_date: firebase.firestore.Timestamp.fromDate(new Date()),
      }

      const docRef = db.collection("ideaBoard");

      docRef.doc(self.id).collection('replies').add(data)
          .then(() => {
            alert('댓글을 등록했습니다.');
            self.getRepliesData();
            self.reply_writer = '';
            self.reply = '';
          })
          .catch((err) => {
            console.log(err)
          })
    },

    getParseDate(date) {
      return date.getFullYear() +
          "-" + (date.getMonth() + 1) +
          "-" + date.getDate() +
          " " + date.getHours() +
          ":" + date.getMinutes() +
          ":" + date.getSeconds()
    },
    deletePost() {
      const self = this;
      const confirmDialog = confirm('정말로 삭제하시겠습니까?');
      if (!confirmDialog) {
        return;
      }
      const db = firebase.firestore();
      const docRef = db.collection("ideaBoard");
      docRef.doc(self.id)
          .delete()
          .then(() => {
            this.$router.replace('/admin/ideaManageList').catch((err) => {
              console.log(err)
            })
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
    },
    getMobile() {
      const self = this;
      const db = firebase.firestore();
      const docRef = db.collection("user");
      docRef.doc(self.uid).get()
          .then((querySnapshot) => {
            const _data = querySnapshot.data();
            if (_data.mobile != null) {
              self.mobile = _data.mobile;
            }
          });
    }
  }
}
</script>

<style>

.sub_content {
  text-align: center;
}

.sub_content h3 {
  font-weight: bold;
}

th {
  background-color: #ececec;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.reply-form li {
  padding: 20px 16px;
  border-bottom: 1px solid #e3e5e8;
  list-style: none;
  font-size: 13px;
}

.likeCnt {
  font-size: 20px;
  font-weight: bold;
  color: #3f51b5;
  display: inline-block;
  width: 85px;
}

.linkCnt {
  font-size: 20px;
  font-weight: bold;
  color: #3f51b5;
  display: inline-block;
  width: 85px;
}

.url-input {
  opacity: 0;
}

.custom-text {
  font-weight: bold;
  font-size: 1.9em;
  border: 1px solid #cfcfcf;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  color: #999;
  background: #fff;
}

.image_center {
  max-width: 100%;
  margin: 0 auto;
}

</style>
