<template>
  <li>
    <div class="member_info">
      <span v-if="replies.reply_writer !== ''">
        <i class="fas fa-user prefix" v-if="replies.uid"></i>
        <strong> {{ replies.reply_writer }}</strong>
      </span>
      <span v-if="replies.reply_writer === ''"> <strong>익명</strong> </span>
      <span> {{ replies.reply_reg_date }} </span>
      <!--      <span> {{ replies.reply_id }} </span>-->
      <span v-if="replies.uid === $store.state.firebase_uid && $store.state.login === true">
        <button @click="deleteReply">삭제</button>
      </span>
    </div>
    <div>
      <p>
        {{ replies.reply_contents }}
      </p>
    </div>
  </li>
</template>

<script>
import firebase from "firebase/app";

export default {
  name: "Reply",
  props: {
    replies: Object
  },
  methods: {
    deleteReply() {
      const self = this;
      console.log(self.replies.reply_id);
      console.log(self.replies.post_id);

      const db = firebase.firestore();
      const docRef = db.collection("ideaBoard");
      docRef.doc(self.replies.post_id)
          .collection("replies")
          .doc(self.replies.reply_id)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
            this.$emit('update');
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
    }
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

.reply-form li {
  padding: 20px 16px;
  border-bottom: 1px solid #e3e5e8;
  list-style: none;
  font-size: 13px;
}

.member_info {
  margin-bottom: 10px;
}

</style>